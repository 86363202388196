<template>
  <main id="body" class="font-sans pt-10">
    <head-component />
    <component :is="layout"></component>
  </main>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import HeadComponent from "@/components/HeadComponent";

export default {
  components: {
    HeadComponent,
    EmptyLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "empty"}-layout`;
    },
  },
};
</script>

<style lang="scss">
#app {
  @apply flex flex-col items-center min-h-screen;
}
#body {
  @apply flex flex-col items-center lg:items-start;
  width: 80%;
}

#logo {
  @apply w-full sm:w-auto;

  svg {
    width: 100%;
  }
}
</style>
