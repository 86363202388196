<template>
  <svg>
    <use xlink:href="@/assets/img/icons.svg#logo" />
  </svg>
</template>

<script>
export default {
  name: "TheLogo",
};
</script>

<style scoped></style>
