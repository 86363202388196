<template>
  <div>
    <router-link id="logo" to="/">
      <the-logo />
    </router-link>
  </div>
</template>

<script>
import TheLogo from "@/components/app/TheLogo";
export default {
  name: "HeadComponent",
  components: { TheLogo },
};
</script>

<style scoped></style>
